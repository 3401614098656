// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';

import Header from '../components/header'
import Layout from '../components/layout';
import Container from '../components/Container';
import Heading from '../components/Heading';
import Box from '../components/Box'
import TagList from '../components/TagList'
import TagListItem from '../components/TagListItem'
import Grid from '../components/Grid'
import Hero from '../components/Hero'
import Flex from '../components/Flex'
import ShowAll from '../components/ShowAll'

const TagsPage = props => {
  const {data} = props
  const industry = data && data.industry.group;
  const company = data && data.company.group;
  const location = data && data.location.group;
  const experience = data && data.experience.group;
  const positiontype = data && data.positiontype.group;
  const { totalCount } = data && data.industry;
  const tagHeader = `Search ${totalCount} job${
    totalCount === 1 ? '' : 's'
  } by filter`;
  return (
    <Layout>
        <Header />
        <Hero title={tagHeader} />
        <Container as="main" id='main-content'>
            <Grid gridTemplateColumns={['none', '1fr']} gridGap={[0, 4]}>
                <Box as="section">
                  <div css="border-bottom: solid 2px #ebebeb">
                    <Flex justifyContent="space-between" alignItems="center" mb="3">
                      <Heading as="h2" fontSize="3" fontWeight="500" pb="0">{tagHeader}</Heading>
                      <ShowAll><Link to="/">Hide filters</Link></ShowAll>
                    </Flex>
                  </div>
                  <Heading as="h2" fontSize="3" fontWeight="500" mt="4">Industry</Heading>
                  <Box mb="5">
                      <TagList>
                      {industry && industry.map(tag => (
                          <TagListItem key={tag.fieldValue}>
                          <Link to={`/jobs-in-${kebabCase(tag.fieldValue)}/`}>
                              {tag.fieldValue} ({tag.totalCount})
                          </Link>
                          </TagListItem>
                      ))}
                      </TagList>
                  </Box>
                  <Heading as="h2" fontSize="3" fontWeight="500">Company</Heading>
                  <Box mb="5">
                      <TagList>
                      {company && company.map(tag => (
                          <TagListItem key={tag.fieldValue}>
                          <Link to={`/jobs-at-${kebabCase(tag.fieldValue)}/`}>
                              {tag.fieldValue} ({tag.totalCount})
                          </Link>
                          </TagListItem>
                      ))}
                      </TagList>
                  </Box>
                  <Heading as="h2" fontSize="3" fontWeight="500">Location</Heading>
                  <Box mb="5">
                      <TagList>
                      {location && location.map(tag => (
                          <TagListItem key={tag.fieldValue}>
                          <Link to={`/jobs-in-${kebabCase(tag.fieldValue)}/`}>
                              {tag.fieldValue} ({tag.totalCount})
                          </Link>
                          </TagListItem>
                      ))}
                      </TagList>
                  </Box>
                  <Heading as="h2" fontSize="3" fontWeight="500">Experience level</Heading>
                  <Box mb="5">
                      <TagList>
                      {experience && experience.map(tag => (
                          <TagListItem key={tag.fieldValue}>
                          <Link to={`/${kebabCase(tag.fieldValue)}-jobs/`}>
                              {tag.fieldValue} ({tag.totalCount})
                          </Link>
                          </TagListItem>
                      ))}
                      </TagList>
                  </Box>
                  <Heading as="h2" fontSize="3" fontWeight="500">Position type</Heading>
                  <Box mb="5">
                      <TagList>
                      {positiontype && positiontype.map(tag => (
                          <TagListItem key={tag.fieldValue}>
                          <Link to={`/${kebabCase(tag.fieldValue)}-jobs/`}>
                              {tag.fieldValue} ({tag.totalCount})
                          </Link>
                          </TagListItem>
                      ))}
                      </TagList>
                  </Box>
              </Box>
            </Grid>
        </Container>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    industry: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      totalCount
    }
    company: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___company) {
        fieldValue
        totalCount
      }
      totalCount
    }
    location: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___location) {
        fieldValue
        totalCount
      }
      totalCount
    }
    experience: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___experience_level) {
        fieldValue
        totalCount
      }
      totalCount
    }
    positiontype: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___position_type) {
        fieldValue
        totalCount
      }
      totalCount
    }
  }
`;